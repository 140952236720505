<template>
  <div>
    <h1>Condiciones IVA</h1>
    <v-list v-if="condiciones.length > 0">
      <v-list-item v-for="condicion in condiciones" :key="condicion.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ condicion.nombre }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-else>
      <v-btn @click="cargarCondicionesIVA">Cargar condiciones IVA default</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CondicionesIva",
  data() {
    return {
      condiciones: [],
    };
  },
  methods: {
    cargarCondicionesIVA() {
      // Realizar la solicitud POST utilizando Axios para cargar las condiciones de IVA por defecto
    this.axios.post('/empresas/condiciones-iva', { nombre: 'default' })
        .then(response => {
          // Manejar la respuesta exitosa si es necesario
          console.log('Respuesta exitosa:', response.data);
        })
        .catch(error => {
          // Manejar el error si es necesario
          console.error('Error al cargar condiciones IVA default:', error.response.data);
        });
    },
  },
  created() {
    this.axios
      .get("/empresas/condiciones-iva")
      .then((response) => {
        this.condiciones = response.data;
      })
      .catch((e) => {
        console.log(e);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e.response.data}`,

          icon: "mdi-information",
          timeout: 3000,
        });
      });
  },
  
};
</script>

<style lang="scss" scoped>
</style>